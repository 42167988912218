<template>
    <el-table :data="tableData" style="width: 100%" class="text-sm">
        <el-table-column :label="'Balansräkning'" :prop="'name'" :width="'400'">
            <template slot-scope="scope">
                <p :class="{ 'font-bold': isNameUppercase(scope.row), 'break-normal pr-5': scope.row.name }">
                    {{ scope.row.name }}
                </p>
            </template>
        </el-table-column>
        <el-table-column v-if="isPeriodDataValid" :label="`${formatDate(balanceSheetData.period.startDate)} - ${formatDate(balanceSheetData.period.endDate)}`" width="200">
            <template slot-scope="scope">
                <template v-if="scope.row.periodRounded !== null && !isNameUppercase(scope.row) && scope.row.periodRounded !== 0">
                    <el-button type="text" class="cursor-pointer text-sm px-4 focus:border-gray-400" @click.native="displayAccounts(scope.row, true)">{{
                        scope.row.periodRounded | swedishNumberFormat(true)
                    }}</el-button>
                </template>
                <template v-else-if="isNameUppercase(scope.row) && scope.row.periodRounded !== 0 && scope.row.previousPeriodRounded !== null">
                    <p class="font-bold px-4">{{ scope.row.periodRounded | swedishNumberFormat(true) }}</p>
                </template>
                <template v-else-if="scope.row.periodRounded == 0">
                    <p v-if="isNameUppercase(scope.row) && scope.row.previousPeriodRounded !== null" class="font-bold px-4">0</p>
                    <el-button v-else type="text" class="cursor-pointer text-sm px-4 focus:border-gray-400" @click.native="displayAccounts(scope.row, true)">0</el-button>
                </template>
            </template>
        </el-table-column>
        <el-table-column v-if="isPreviousPeriodDataValid" :label="`${formatDate(balanceSheetData.previousPeriod.startDate)} - ${formatDate(balanceSheetData.previousPeriod.endDate)}`" width="200">
            <template slot-scope="scope">
                <template v-if="scope.row.previousPeriodRounded !== null && !isNameUppercase(scope.row) && scope.row.previousPeriodRounded !== 0">
                    <el-button type="text" class="cursor-pointer text-sm px-4 focus:border-gray-400" @click.native="displayAccounts(scope.row, false)">
                        {{ scope.row.previousPeriodRounded | swedishNumberFormat(true) }}
                    </el-button>
                </template>
                <template v-else-if="isNameUppercase(scope.row) && scope.row.previousPeriodRounded !== 0 && scope.row.previousPeriodRounded !== null">
                    <p class="font-bold px-4">{{ scope.row.previousPeriodRounded | swedishNumberFormat(true) }}</p>
                </template>
                <template v-else-if="scope.row.previousPeriodRounded == 0">
                    <p v-if="isNameUppercase(scope.row) && scope.row.previousPeriodRounded !== null" class="font-bold px-4">0</p>
                    <el-button v-else type="text" class="cursor-pointer text-sm px-4 focus:border-gray-400" @click.native="displayAccounts(scope.row, false)">0</el-button>
                </template>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import moment from "moment";

export default {
    props: {
        tableData: Array,
        balanceSheetData: Object,
    },
    methods: {
        formatDate(date) {
            if (!date) return;
            return moment(date).format("YYYY.MM.DD");
        },
        isNameUppercase(row) {
            if (row && row.name && typeof row.name === "string") {
                return row.name === row.name.toUpperCase();
            } else {
                return false;
            }
        },
        displayAccounts(row, isPeriod) {
            this.$emit("display-accounts", row, isPeriod);
        },
    },
    computed: {
        isPeriodDataValid() {
            return this.balanceSheetData && this.balanceSheetData.period && this.balanceSheetData.period.startDate && this.balanceSheetData.period.endDate;
        },
        isPreviousPeriodDataValid() {
            return this.balanceSheetData && this.balanceSheetData.previousPeriod && this.balanceSheetData.previousPeriod.startDate && this.balanceSheetData.previousPeriod.endDate;
        },
    },
};
</script>
